import React, {Component, FunctionComponent} from 'react';
import "./sectionstyle.css"


type props={
    children: React.ReactNode,
    bg?:string
}

export const Section : FunctionComponent<props> =({children, bg})=>{
    return(
        <section className={bg ? "section__container bg": "section__container"}>
            {children}
        </section>
    )
}
