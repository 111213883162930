import React, {FunctionComponent} from "react";
import "./donationcardstyle.css"
import  pic from "../../../assets/pic3.jpg"

type props={
    img:any,
    title:string
}

export const DonationCard: FunctionComponent<props> =({img,title})=>{
    return(
        <div className={"donation__card-container"}>
            <div className={"donation__image"}>
                <img alt={""} src={img}  style={{width:"100%", height:"100%", objectFit:"cover"}}/>
            </div>
            <div className={"donation__title"}>
                <h3>{title}</h3>
            </div>
        </div>
    )
}
