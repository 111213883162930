import React, { FunctionComponent, } from 'react';
import {NavLink} from 'react-router-dom'
import './navbar.css'
import {BsChevronRight} from "react-icons/bs";
import {Button} from "../components/button/Button";

const Navbar : FunctionComponent = () =>{
    return(
        <nav className={"navbar"}>

                <NavLink to="/" className={({isActive})=>(isActive ? "navbar-item active" : "navbar-item")}>Home</NavLink>
                <NavLink to="/about" className={({isActive})=>(isActive ? "navbar-item active" : "navbar-item")}  >About</NavLink>
                <NavLink to="/our-work" className={({isActive})=>(isActive ? "navbar-item active" : "navbar-item")}>Our work</NavLink>
                <NavLink to="/ways-to-help" className={({isActive})=>isActive ? "navbar-item active" : "navbar-item"}>Ways to help</NavLink>
                <NavLink to="/contact" className={({isActive})=>isActive ? "navbar-item active" : "navbar-item"}>Contact</NavLink>
        </nav>
    )
}

export default Navbar
