import React, {FunctionComponent} from "react";
import cover from "../../assets/foto.jpg"
import case1 from "../../assets/case1.jpg"
import case2 from "../../assets/case2.jpg"
import case3 from "../../assets/case3.jpg"
import case5 from "../../assets/case5.jpg"
import case6 from "../../assets/case6.jpg"
import "./ourworkstryle.css"
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {Section} from "../../components/section/Section";
import {DonationCaseH} from "../../components/cards/donationCaseHorizontal/DonationCase";

export const OurWork: FunctionComponent =()=>{
    return(
        <div>
            <div className={"about__hero"}>
                <div className={"hero__overlay"}>
                    <img alt={""} src={cover}
                         style={{height:"100%",width:"100%", objectFit:"cover", }}
                    />
                    <div className={"hero__overlay-content"}>
                        <h3 className={"hero__title"}>Our work</h3>
                    </div>
                </div>
            </div>
            <div>
                <Breadcrumb pageTitle={"Our work"} />
            </div>
            <section>
                <div className={"dynamic__cards"}>
                    {/*<div className={"ways__first-section"}>*/}

                            <DonationCaseH
                                title={"Improving Education"}
                                content={"Help Kikwit promotes the education of less fortunate students through scholarship, tuition, and school and academic supplies. To date, Help Kikwit invested in the education of over 3,000 unsupported children in high schools and elementary schools (most of them orphans) with full tuition, and necessary school supplies. We have expanded this activity to reach out to other regions of the Congo and Africa via our NGO-partners."}
                                img={case1}
                            />


                            <DonationCaseH
                                title={"More Assistance in Transportation"}
                                content={"Help Kikwit provides means of transportation (trucks, motorcycles, and other motorvehicles) to charitable institutions in underdeveloped regions. From 1997 to 2007, Help Kikwit was able to ship SUV trucks and fund motorcycles and multiple bicycles to help with the transportation of ministers within the Diocese of Kikwit in the Democratic Republic of the Congo.\n" +
                                "\n" +
                                "Help Kikwit is grateful for the donation of a Yamaha Motorcycle to the pastoral ministry of priests in the Minor Seminary of Kinzambi (Diocese of Kikwit, D.R.C.)."}
                                img={case2}
                            />


                            <DonationCaseH
                                title={"Medical Equipment"}
                                content={"Help Kikwit equips hospitals and medical clinics in developing regions with materials and medicines, as well as interns and volunteers in collaboration with other charitable institutions.\n" +
                                "\n" +
                                "In the past we shipped containers of medical equipment in our partner-hospitals and clinics in the D.R. Congo. We have dealt with such foundations and NGOs as American Medical Foundation (AMRF), Gif in Kind, and Hope for the City who have provided Help Kikwit with needed supplies, as well as prepared and shipped containers to help those clinics and hospitals. Currently, Help Kikwit has received requests from several struggling medical facilities in Africa whose needs we incorporate in our fund raising activities."}
                                img={case3}
                            />

                            <DonationCaseH
                                title={"Improving Quality of Life"}
                                content={"Help Kikwit assists under-lodged families with more sturdy building materials and decent houses. Help Kikwit initially helped 30 families in the region of Kikwit to acquire more decent housing by providing them with corrugated roofing materials, cemented pavement, doors and windows. The selected families had to contribute 40,000 adobe bricks of their own making.\n" +
                                    "\n" +
                                    "Since 80% of people still live under mud and leaf-roofed huts in rural areas of poor countries, Help Kikwit expands its mission to assist as many families as possible"}
                                img={case5}
                            />

                            <DonationCaseH
                                title={"Globalclinics and Entrepreneurship"}
                                content={"Help Kikwit uses GlobaClinics as a network of clinics that enable our sponsors to help the least fortunate elsewhere in the world (including loved-ones) to pay for the health care that they themselves cannot afford. GlobalClinics allows sponsors and people of good will to send money directly to a selected clinic in the network. This investment allows the sponsorees or patients to receive pre-paid care.\n" +
                                    "\n" +
                                    "Help Kikwit carefully vets each clinic in the network, and ensures transparency in both the money transferred and the quality of care the sponsored patients receive. (GlobalClinics.net)."}
                                img={case6}
                            />

                        {/*<div className={"work__section"}>*/}
                        {/*    <DonationCaseH*/}
                        {/*        title={"Globalclinics and Entrepreneurship"}*/}
                        {/*        content={"Help Kikwit uses GlobaClinics as a network of clinics that enable our sponsors to help the least fortunate elsewhere in the world (including loved-ones) to pay for the health care that they themselves cannot a ord. GlobalClinics allows sponsors and people of good will to send money directly to a selected clinic in the network. This investment allows the sponsorees or patients to receive pre-paid care. Help Kikwit carefully vets each clinic in the network, and ensures transparency in both the money transferred and the quality of care the sponsored patients receive. (GlobalClinics.net)."}*/}
                        {/*        img={case4}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className={"work__section"}>*/}
                        {/*    <DonationCaseH*/}
                        {/*        title={"Improving Transportation"}*/}
                        {/*        content={"Help Kikwit promotes the education of less fortunate students through scholarship, tuition, and school and academic supplies. To date, Help Kikwit invested in the education of over 3,000 unsupported children in high schools and elementary schools (most of them orphans) with full tuition, and necessary school supplies. We have expanded this activity to reach out to other regions of the Congo and Africa via our NGO-partners."}*/}
                        {/*        img={cover}*/}
                        {/*    />*/}
                        {/*</div>*/}

                    </div>

                {/*</div>*/}
            </section>


        </div>
    )
}
