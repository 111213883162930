import React, {FunctionComponent, useState} from "react";
import cover from "../../assets/foto.jpg"
import "./contactstyle.css"
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {Section} from "../../components/section/Section";
import {Button} from "../../components/button/Button";
import {MdLocationPin} from "react-icons/md";
import {HiOutlinePhone} from "react-icons/hi";
import TextInput from "../../components/textInput/TextInput";

export const Contact: FunctionComponent = ()=>{
    const [fullName,setFullName] = useState("")
    const [email,setEmail] = useState("")
    const [message,setMessage] = useState("")

    return(
        <div>
            <div className={"about__hero"}>
                <div className={"hero__overlay"}>

                    <img alt={""} src={cover}
                         style={{height:"100%",width:"100%", objectFit:"cover", }}
                    />
                    <div className={"hero__overlay-content"}>
                        <h3 className={"hero__title"}>Contact</h3>
                    </div>
                </div>
            </div>
            <div>
                <Breadcrumb pageTitle={"Contact"} />
            </div>
            <Section>
                    <div className={"contact__container"}>
                        <div>
                            <h1 className={"contact__h1"}>Contact us for answers to your questions</h1>
                        </div>
                        <div className={"info__container"}>
                            <div className={"contact__content"}>

                                <p className={"contact__paragraph"}>Help Kikwit leadership is based in the USA with others based in Africa.</p>
                                <div className={"contact__info"}>
                                    <MdLocationPin className={"contact__icon"} />
                                    <p>2115 Summit Ave mail # 4044,
                                        St. Paul, MN 55105</p>
                                </div>
                                <div className={"contact__info"}>
                                    <HiOutlinePhone  className={"contact__icon"}/>
                                    <a href={"tel:+16513073692"}>+1-651-307-3692</a>
                                </div>
                            </div>
                            <div className={"contact__form"}>
                                <form
                                    onSubmit={(e)=>{
                                        e.preventDefault()
                                        alert(`Your name is ${fullName} your email is ${email}and your message is: ${message}`)
                                    }}
                                >
                                    <TextInput type={"text"} value={fullName} placeholder={"Full Name"} onChangeHandler={(e:any)=>{setFullName(e.target.value)}} />
                                    <TextInput type={"email"} value={email} placeholder={"Email"} onChangeHandler={(e:any)=>{setEmail(e.target.value)}} />
                                    <TextInput type={"text"} value={message} placeholder={"Message"} onChangeHandler={(e:any)=>{setMessage(e.target.value)}} isTextArea={true} />
                                    <Button title={"Submit"} type={"submit"} onClick={()=>{}} style={{width:"100%", display:"flex", justifyContent:"center"}}/>
                                </form>



                            </div>
                        </div>

                    </div>
            </Section>



        </div>
    )
}
