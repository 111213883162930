import React, {FunctionComponent, } from 'react';
import "./body.css"

type  props={
    children:any
}

export const Body : FunctionComponent<props> = ({children})=>{
    return(
        <div className='body__wrapper'>{children}</div>
    )

}
