import React from "react";
import { Link } from "react-router-dom"
import {BsChevronRight} from "react-icons/bs";
import "./breadcrumbstyle.css"


type props = {
    pageTitle : string
    description? : string
}

export const Breadcrumb =({pageTitle, description} : props)=>{
    return (
        <section className="breadcrumb__container">
                <div style={{display:"flex", alignItems:"center"}}>
                    <div className={"container__padding"} style={{display:"flex", alignItems:"center", gap:"1rem", paddingBottom:10}}>
                        <Link to="/" className="breadcrumb__home" >Home</Link>
                        <BsChevronRight />
                        <strong style={{color:"#20b2aa"}}>{pageTitle}</strong>
                    </div>

                </div>
        </section>
    )
}
