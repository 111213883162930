import React, {FunctionComponent, } from 'react';
import "./foreheader.css"
import {BsTelephoneFill} from "react-icons/bs";


const ForeHeader: FunctionComponent=()=>{
    return(
        <div className='foreheader__container container__padding'>

            <p className={"help__communities"}>Help communities as you work with Kikwit diocese.</p>
            <div className={"phone__section"}>
                <BsTelephoneFill className={"phone__icon"}/>
                <a href={"tel:+16513073692"}>+1-651-307-3692</a>
            </div>

        </div>
    )
}

export default ForeHeader
