import React, {FunctionComponent} from "react";
import cover from "../../assets/foto.jpg"
import "./waystohelpstyle.css"
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {Section} from "../../components/section/Section";
import {BoardMember} from "../../components/cards/boardMember/BoardMember";
import {Button} from "../../components/button/Button";
import {BsChevronRight} from "react-icons/bs";
import second from "../../assets/pic2.png";
import {StatsCard} from "../../components/cards/stats/StatsCard";

export const WaysToHelp: FunctionComponent =()=>{
    return(
        <div>
            <div className={"about__hero"}>
                <div className={"hero__overlay"}>
                    <img alt={""} src={cover}
                         style={{height:"100%",width:"100%", objectFit:"cover", }}
                    />
                    <div className={"hero__overlay-content"}>
                        <h3 className={"hero__title"}>Ways to Help</h3>
                    </div>
                </div>
            </div>
            <div>
                <Breadcrumb pageTitle={"Ways to Help"} />
            </div>
            <Section>
                <div className={"ways__container"}>
                    <div className={"ways__first-section"}>
                        <div className={"ways__paragraph"}>
                            <h3>Education of Unsupported Children</h3>
                            <p>
                                Help Diocese of Kikwit believes that a better world for God’s children is possible when
                                we work together. When you join Help Diocese of Kikwit community by making a gift or supporting
                                our work, you are investing in the pastoral, spiritual, educational and whole care of unsupported
                                children and families in developing areas of the world.

                                No matter how small, your contribution makes an important impact. And you can trust that Help Kikwit
                                will use your donation wisely. There are so many ways to help. Support Make a one-time donation to start
                                transforming lives Monthly giving You can provide critical resources to struggling communities when they
                                need it most: every day.
                            </p>
                        </div>
                        <div className={"ways__paragraph"}>
                            <h3>Fundraise for us</h3>
                            <p>
                                Bring together your family, friends, neighbors and co-workers to make a di erence with an online campaign or community event.
                            </p>
                        </div>
                        <div className={"ways__paragraph"}>
                            <h3>Matching Gifts</h3>
                            <p>
                                Double your impact by asking your employer to match your gift.
                            </p>
                        </div>
                        <div className={"ways__paragraph"}>
                            <h3>Gifts of stock</h3>
                            <p>
                                Share your shares, meet your charitable goals and maximize your tax savings.
                            </p>
                        </div>
                        <div className={"ways__paragraph"}>
                            <h3>Donor-advised fund</h3>
                            <p>
                                Using your donor-advised fund to support Help Kikwit is an excellent way to simplify your charitable giving and meet your philan- thropic goals.
                            </p>
                        </div>
                        <div className={"ways__paragraph"}>
                            <h3>Planned giving</h3>
                            <p>
                                Leave a legacy through estate planning, bequests and gift annuities.
                            </p>
                        </div>

                    </div>
                    <div className={"ways__second-section"}>
                        <div className={"ways__paragraph"}>
                            <h3>DONOR TARGET AREA</h3>

                        </div>
                    </div>
                </div>
            </Section>


        </div>
    )
}
