import React, {FunctionComponent} from "react"
import "./buttonstyle.css"

type props={
    title:string,
    icon?:any,
    onClick:any,
    style?:any
    type?:any
}
export const Button : FunctionComponent<props> =({title, icon, onClick, style, type})=>{
    return(
        <button onClick={onClick} style={style} type={type}>
            {title}
            {icon}
        </button>
    )
}
