import React , {FunctionComponent} from "react"
import "./boardmember.css"

type props ={
    title:string
    name:string
}

export const BoardMember : FunctionComponent<props> = ({title, name,})=>{
    return(
        <div className={"board__container"}>
            <div className={"board__content"}>
                <h2>{name}</h2>
                <p className={"text__paragraph-color"}>{title}</p>
            </div>
        </div>
    )
}
