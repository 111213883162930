import React, {FunctionComponent} from "react"
import "./donationHstyle.css"
import {CircleButton} from "../../button/CircleButton";
import {Button} from "../../button/Button";
import {useNavigate} from "react-router-dom";


type props={
    img:any,
    title:string,
    content:string
}


export const DonationCaseH : FunctionComponent<props> =({img, title, content})=>{
    const navigate=useNavigate();

    return(
            <div className={"donation__h"}>
                <div className={"donation__main-card"}>

                        <div className={"donation__h-img-container"}>
                            <img src={img} className={"donation__h-img"} alt={""} />
                        </div>

                        <h4>{title}</h4>
                        <p>{content}</p>

                </div>

                <div className={"secondary__card"}>
                    <div className={"second__card-title"}>
                        <h4>DONOR TARGET AREA</h4>
                    </div>

                    <img src={img} className={"secondary__card-img"} alt={""} />
                    <div>
                        <p>Life in Kikwit</p>
                        <p>Kikwit Town</p>
                    </div>
                    <div className={"btn__price"}>
                        <Button title={"DONATE"} onClick={()=>{ navigate("/donate")}} style={{borderRadius:"100px", padding:"0.3rem 1rem"}} />
                        <p>
                            Price <span>$100 - $1000</span>
                        </p>
                    </div>


                </div>

            </div>

    )
}
