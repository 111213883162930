import React, {FunctionComponent, } from "react"
import "./textInput.css"

type props ={
    placeholder:string,
    value:string,
    style?:any
    onChangeHandler:any,
    isTextArea?:boolean,
    type:string
}

const TextInput : FunctionComponent<props> =({placeholder, value, onChangeHandler, type, isTextArea, style})=>{

    // const onChangeHandler =(e:any)=>{
    //     setEmail(e.target.value)
    // }
    // const onChangeHandler =(e:any)=>{
    //     setEmail(e.target.value)
    // }
    return(
        <section className={"textInput__container"}>
            {
                !isTextArea
                    ? <input type={type ? type :"text"} required className={"textInput__input"} style={style} placeholder={placeholder} defaultValue={value}  onChange={onChangeHandler}  />
                    : <textarea required  placeholder={placeholder} value={value} onChange={onChangeHandler} className={"textInput__input_textArea"} />
            }
            {/*<input className={"textInput__input"} placeholder={placeholder} value={value} onChange={onChangeHandler} />*/}
        </section>
    )
}
export default TextInput
