import React, {FunctionComponent} from 'react';
import "./notfoundstyle.css"


export const PageNotFound: FunctionComponent =()=>{
    return (
        <div className={"notF__container"}>
            <div>
                <p>Oupps!!!!!</p>
                <p>Page not found</p>
            </div>

        </div>
    )
}
