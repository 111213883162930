import React, {FunctionComponent} from "react";
import cover from "../../assets/foto.jpg"
import "./aboutstyle.css"
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {Section} from "../../components/section/Section";
import {BoardMember} from "../../components/cards/boardMember/BoardMember";
import {Button} from "../../components/button/Button";
import {BsChevronRight} from "react-icons/bs";
import second from "../../assets/pic2.png";
import {StatsCard} from "../../components/cards/stats/StatsCard";

export const About: FunctionComponent =()=>{
    return(
        <div>
            <div className={"about__hero"}>
                <div className={"hero__overlay"}>
                        <img alt={""} src={cover}
                             style={{height:"100%",width:"100%", objectFit:"cover", }}
                        />
                        <div className={"hero__overlay-content"}>
                            <h3 className={"hero__title"}>About</h3>
                        </div>
                </div>
            </div>
            <div>
                <Breadcrumb pageTitle={"About"} />
            </div>
            <Section>
                <div className={"about__board-title"}>
                    <h2>Board Members</h2>
                </div>
                <div className={"board__members"}>
                    <BoardMember name={"Rev. Jean-Pierre Bongila"} title={"President"} />
                    <BoardMember name={"Mr. William Martin"} title={"Board member"} />
                    <BoardMember name={"Rev. Francois-Xavier Eale"} title={"Board member"} />
                </div>
            </Section>
            <section className={"statistics"}>

                <div className={"stats__overlay container__padding"}>
                    <div className={"stats__container"}>
                        <StatsCard indicator={"Villages Reached"} value={850} />
                        <StatsCard indicator={"Schools Served"} value={190} />
                        <StatsCard indicator={"Hospitals Supported"} value={5} />
                        <StatsCard indicator={"Families Served"} value={290} />
                    </div>
                </div>

            </section>

            <Section>
                <div className={"home__second-section"} >
                    <div className={"second__section-left layout__padding"}>
                        <h1>
                            Donate to help children, communities and families in the Kikwit region and beyond to live better lives now and in the future.
                        </h1>
                        <div style={{height:"2rem"}} />
                        <p>
                            In a world full of challenges, Help Diocese of Kikwit sees the possibility for transformative change.
                            We are expanding our work to various communities, empowering people to survive crisis,
                            allowing ministers to reach out to people, assisting families to build decent houses in order
                            to live better lives and transform their communities for good.
                        </p>
                        <div style={{height:"2rem"}} />
                    </div>
                    <div className={"second__section-right layout__padding"} >
                        <img alt={""} src={second} style={{width:"100%"}} />
                    </div>
                </div>

            </Section>
        </div>
    )
}
