import React, {FunctionComponent, useEffect, useState,} from 'react';
import "./header.css"
import logo from "../../assets/dk-logo.png"
import {Link, useLocation} from "react-router-dom";
import Navbar from "../../navBar/Navbar";
import {Button} from "../button/Button";
import {BiMenu} from "react-icons/bi";
import {RxCross1} from "react-icons/rx";
import { useNavigate } from "react-router-dom";


const Header: FunctionComponent=()=>{
    const [hasScrolled, setHasScrolled] = useState(false)
    const navigate=useNavigate()
    const [sideBar, setSideBar] = useState(false)

    const showSidebar =() =>setSideBar(!sideBar)

    const sideBarData = [
        {
            title: "Home",
            path: "/",
            cName:"nav-text",
        },
        {
            title: "About",
            path: "/about",
            cName:"nav-text",
        },
        {
            title: "Our work",
            path: "/our-work",
            cName:"nav-text",
        },
        {
            title: "Ways to help",
            path: "/ways-to-help",
            cName:"nav-text",
        },
        {
            title: "Contact",
            path: "/contact",
            cName:"nav-text",
        },

        // {
        //     title: "",
        //     path: "",
        //     cName:"",
        // },
    ]

    const location = useLocation()
    useEffect( () => {
        if(location.hash){
            document.getElementById(location.hash.substring(1))?.scrollIntoView({behavior : "smooth", inline : "start" })
        }else{
            window.scrollTo( 0, 0 )
        }
        const onScroll = () => {
            setHasScrolled( window.scrollY > 60 )
        }
        window.addEventListener("scroll", onScroll)

        return () => window.removeEventListener("scroll", onScroll)

    }, [location])
    return(
        <div className={hasScrolled ? "header__fixed" :'header__container'}>
            <div className={"header__logo"}>
                <Link to={"/"} className={"logo"}  >
                    <h2>Help Kikwit</h2>
                </Link>

            </div>
            <div className={"header__menu-items"}>
                    <Navbar />

                    <div className={"header__button"}>
                        <Button  style={{padding:"1rem 4rem"}}  title={"DONATE"} onClick={()=>{ navigate("donate")}}/>
                    </div>

                    <div className={"header__hamburger"}>
                        <BiMenu className={"hamburger__icon"} onClick={()=>showSidebar()} />
                        <nav className={sideBar ? "nav-menu active": "nav-menu"}>
                            <ul className={"nav-menu-items"} onClick={()=>showSidebar()} >
                                <li className={"navbar-toggle"}>
                                    <div className="menu-bars">
                                        <RxCross1 />
                                    </div>
                                </li>
                                {
                                    sideBarData.map((item:any, index  )=><li key={index} className={item.cName}>
                                        <Link to={item.path} >{item.title}</Link>
                                    </li>)
                                }

                            </ul>
                        </nav>
                    </div>

            </div>
        </div>
    )
}

export default Header
