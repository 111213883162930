import React, {FunctionComponent} from "react";
import "./maincampaign.css"
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import climbing from "../../assets/build.jpg";
import blscool1 from "../../assets/blschool/blschool1.jpg";
import blscool2 from "../../assets/blschool/blschool2.jpg";
import blscool3 from "../../assets/blschool/blschool3.jpg";
import { PayPalScriptProvider,} from "@paypal/react-paypal-js";
import {Section} from "../../components/section/Section";
import {PayPalButtonWrapper} from "../../components/paypalButtonWrapper/PaypaButtonWrapper";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export const MainCampaignPage: FunctionComponent =()=>{
    const initialOptions = {
        "client-id": "AWRSn7MVCmzR2VbLdjV6lCD8f_P6cY95ZcRIXuOhKED8RJiRviwzVwFsRvQSO7E6WaRZPhQOcRflbCMV",
        currency: "USD",
        intent: "capture",
        // "data-client-token": "EG-MofM27rOw-L_AX3oDJVIExq9Wb0UGq50pULoZPNRz8sWrLiLUZghQHngW_xKex2zkbDRQ5KIE9Mv5",
    };

    return(
        <div>
            <div className={"about__hero"}>
                <div className={"hero__overlay"}>
                    <img alt={""} src={climbing}
                         style={{height:"100%",width:"100%", objectFit:"cover", }}
                    />
                    <div className={"hero__overlay-content"}>
                        <h3 className={"hero__title"}>Kilimanjaro Charity Climb for Bilingual School of Leadership of Kikwit</h3>
                    </div>
                </div>
            </div>
            <div>
                <Breadcrumb pageTitle={"Bilingual School of Leadership"} />
            </div>

            <Section>
                <div className={"ways__container"}>
                    <div className={"ways__first-section"}>
                        <div className={"ways__paragraph"}>
                            {/*<img alt={""} src={cards} />*/}
                            <div className={"home__event-current"}>
                                <small>Current Activity</small>
                            </div>
                            {/*<h2>Kikwit Kilimanjaro Charity Climb</h2>*/}
                            {/*<p>Goal: $7000 / $ 70,000</p>*/}
                            {/*<p>Join Us for an Adventure of a Lifetime!</p>*/}

                            <p style={{textAlign: "justify"}}>
                                The mission of Bilingual School of Leadership of Kikwit (BSL) is to
                                educate bilingual ethical leaders of tomorrow, fluent in both French
                                and English, trained in critical thinking skills and concerns for the
                                common good, to become change making entrepreneurs in their
                                communities, the country and the globe. Our one-of-a-kind approach begins with primary
                                learning focused on French and is bilingualized by secondary level. We are dedicated to educating our students in
                                critical thinking skills and change making mindset to cultivate ethical leaders who will become change makers
                                in their neighborhoods, nations, and across the planet.
                            </p>
                            <div />
                            <p style={{ textAlign: "justify"}}>
                                Situated 320 miles southwest of the capital city Kinshasa of the Democratic Republic of the Congo, the Bilingual School
                                of Leadership of Kikwit is based in a 30-acre, site 17 miles away from the city of Kikwit, the headquarters of the Catholic
                                Diocese of Kikwit. The D.R Congo, particularly the Kikwit area, faces the imperative task of preparing an elite generation
                                who can take part in the challenges of the 21st century. The wide-reaching financial emergency and drop in educational levels
                                nationwide have lessened Kikwit’s ability to educate the elite of the province and country.
                                Therefore, we have a vision of constructing a private campus that houses the Bilingual School of Leadership of Kikwit (BSL).
                                The BSL will have a private primary and secondary school in Leadership and Computer Science as well as a Leadership Training Center.
                                To counter the downfall of educational quality in Kikwit and the DR Congo, BSL is determined to collaborate with schools in the USA,
                                in order to choose qualified bilingual teachers and intellectually gifted students that will be tested all over the provinces of the
                                country. Traditional teaching will be enhanced with up-to-date IT facilities and empowering education in leadership and critical thinking.
                                Due to its location on the National-1 higway and its closeness to Kikwit (17 miles), BSL gives an effortless entrance to students from Kikwit and
                                the other provinces. Moreover, parents are able to pick among dormitory, semi-dormitory, and commuting systems for their children.
                                This venture covers Phase-1 of the entire construction project.
                            </p>

                            <Carousel>
                                <div>
                                    <img src={blscool1} />
                                </div>
                                <div>
                                    <img src={blscool2} />
                                </div>
                                <div>
                                    <img src={blscool3} />
                                </div>
                            </Carousel>

                        </div>

                    </div>
                    <div className={"ways__second-section sticky"}>
                        <div className={"ways__paragraph "}>
                            <h1 style={{textAlign: "center", color: "#20b2aa", fontSize: "40px"}}>DONATE NOW</h1>
                            <PayPalScriptProvider options={initialOptions}>
                                <PayPalButtonWrapper />
                            </PayPalScriptProvider>

                        </div>
                    </div>
                </div>
            </Section>
        </div>

    )
}
