import React , {FunctionComponent} from "react"
import "./datacardstyle.css"

type props ={
    icon:any
    title:string
    value:string
}

export const DataCard : FunctionComponent<props> = ({title, value, icon})=>{
    return(
        <div className={"data__container"}>
            <div className={"data__icon"}>
                {icon}
            </div>
            <div className={"data__content"}>
                <h2>{value}</h2>
                <p className={"text__paragraph-color"}>{title}</p>
            </div>
        </div>
    )
}
