import { createContext, useContext } from "react"

export interface Donation{
    status:string
    setStatus:(status:string)=>void
}


// export interface DonationContext{
//     status: ""
//     setStatus:(Sub: Donation)=>void
// }


export const GlobalContext = createContext<Donation>({
    status: "",
    setStatus:()=>{}
})


export const useGlobalContext = ()=> useContext(GlobalContext)
