import React, {FunctionComponent, } from 'react';
import "./footer.css"
import {Section} from "../section/Section";
import footerLogo from "../../assets/footer-logo.png"
import {FaFacebookF, FaTwitter} from "react-icons/fa";
import {NavLink} from "react-router-dom";


const Footer: FunctionComponent=()=>{
    const currentYear =  new Date().getFullYear();
    return(
        <div className='footer__container'>

            <div className='footer__content'>

            <Section>
                <div className={"footer__container_second"}>
                    <div className={"second__element"}>
                        <div style={{height:"5rem"}}>
                            <img alt={""} className={"footer__logo"} src={footerLogo} />
                        </div>
                            <p>Help Kikwit is a 501(c)3 charity. Your gift is tax-deductible as allowed by U.S. law.</p>

                        <div style={{display:"flex", gap:"1rem", marginTop:"1rem"}}>
                            <FaFacebookF />
                            <FaTwitter />
                        </div>
                    </div>
                    <div className={"second__element"}>
                        <div className={"footer__item_title"}>
                            <h3 style={{fontSize:"1.5rem"}} className='footer__paragraph_title'>DONATE</h3>
                        </div>


                        <div>
                            <NavLink to="/ways-to-help" className={({isActive})=>(isActive ? "footer-navbar-item active" : "footer-navbar-item")}>Way to help</NavLink>
                        </div>


                    </div>
                    <div className={"second__element"}>
                        <div className={"footer__item_title"}>
                            <h3 style={{fontSize:"1.5rem"}} >TAKE ACTION</h3>
                        </div>


                        <div>
                            <NavLink to="/about" className={({isActive})=>(isActive ? "footer-navbar-item active" : "footer-navbar-item")}>About us</NavLink>
                            <NavLink to="/our-work" className={({isActive})=>(isActive ? "footer-navbar-item active" : "footer-navbar-item")}  >Our Work</NavLink>
                        </div>


                    </div>
                    <div className={"second__element"}>
                        <div className={"footer__item_title"}>
                            <h3 style={{fontSize:"1.5rem"}}>TWITTER FEED</h3>
                        </div>


                        <div>
                            <p>
                                <span style={{color:"#2c804e"}}><FaTwitter /> @helpKikwit </span>
                                using your resources for the common good.
                            </p>
                        </div>
                        <div style={{height:"1rem"}} />

                        <div>
                            <p>
                                <span style={{color:"#2c804e"}}> <FaTwitter /> @helpKikwit </span>
                                 we are committed to helping kikwit diocese.
                            </p>
                        </div>

                    </div>
                </div>

            </Section>

                    {/*<div>*/}
                    {/*    <div style={{height:"0.5px", backgroundColor:"white"}} />*/}
                    {/*    <small className='footer__copyright'>Copyright-{currentYear}</small>*/}
                    {/*</div>*/}
            </div>
        </div>
    )
}

export default Footer
