import React, {FunctionComponent, useState,} from "react"
import Cover from "../../assets/case4.jpg"
import first from "../../assets/foto.jpg"
import project1 from "../../assets/home1.jpg"
import climbing from "../../assets/cm.jpg"
import project2 from "../../assets/home2.jpg"
import project3 from "../../assets/projects3.jpg"
import second from "../../assets/pic2.png"
import last from "../../assets/pic4.jpg"
import "./home.css"
import {Section} from "../../components/section/Section";
import {DonationCard} from "../../components/cards/donation/DonationCard";
import {DataCard} from "../../components/cards/data/DataCard";
import {Button} from "../../components/button/Button";
import {BsChevronRight} from "react-icons/bs";
import {IoIosGitNetwork} from "react-icons/io";
import {HiOutlinePhone, HiOutlineUserGroup} from "react-icons/hi"
import {RiHospitalLine} from "react-icons/ri"
import TextInput from "../../components/textInput/TextInput";
import {AiOutlineMail} from "react-icons/ai";
import cover from "../../assets/foto.jpg";
import {useNavigate} from "react-router-dom";



export const Home: FunctionComponent =()=>{
    const navigate=useNavigate()
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    return(
        <div className={"home__wrapper"}>

            <section>
                <div className={"about__hero"}>
                    <div className={"hero__overlay"}>
                        <img alt={""} src={Cover}
                             style={{height:"100%",width:"100%", objectFit:"cover", }}
                        />
                        <div className={"home__hero__overlay-content container__padding"}>
                            <div >
                                <h3 className={"hero__title"}>Suffering Through Lack Of Education And Healthcare</h3>
                                <div style={{height:"2rem"}} />
                                <p>God's people are in crisis particularly in vulnerable communities, and organizations such
                                    as Help Kikwit are trying to help. Children and families in regions similar to Kikwit are
                                    suffering through lack of education and healthcare, poverty and disaster, and hopelessness.
                                </p>
                                <div style={{height:"2rem"}} />
                                <Button title={"PARTICIPATE"} onClick={()=>{}} />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Section>
                <div className={"home__first-section"}>
                    <div className={"first-section-left layout__padding"}>
                        <small>MISSION</small>
                        <h1>
                            As a nonprofit organization, Help Kikwit provides a platform, which
                            individuals and organizations can use to foster entrepreneurship,
                            self development, and the welfare of less fortunate people in Africa,
                            particularly in the D.R. Congo, and in other developing countries.
                        </h1>
                        <div style={{height:"2rem"}} />
                        <p>
                            Help Kikwit in Zaire (D.R. Congo) was created in 1997 as a
                            California nonprofit organization, and is currently based in St. Paul,
                            MN. In the D.R. Congo, Help Kikwit supports communities and organizations,
                            particularly the Diocese of Kikwit, by promoting the spiritual, social and economic
                            development of the poorest families and communities. This partner-organization reaches
                            out to 850 villages with a total population of over 2,600,000 including 290,000 families,
                            190 schools, 480,000 students, and 5 regional hospitals
                            and several small medical clinics in the D.R. Congo.
                        </p>
                        <div style={{height:"2rem"}} />
                        <p>Help Kikwit is a 501(c)3 charity. Your gift is tax-deductible as allowed by U.S. law.</p>

                        <div className={"first__section-buttons"}>
                            <Button  title={"MORE ABOUT US"} icon={<BsChevronRight />} onClick={()=>{ navigate("about")}}/>
                            <Button  title={"DONATE"} icon={<BsChevronRight />} onClick={()=>{ navigate("donate")}}/>
                        </div>

                    </div>
                    <div className={"first-section-right layout__padding"} >
                        <img alt={""} src={first} style={{width:"100%"}} />
                        <div className={"data-cards"}>
                            <DataCard title={"People"} icon={<HiOutlineUserGroup />} value={"2.6 M"} />
                            <DataCard title={"Families"} icon={<IoIosGitNetwork />} value={"290 K"} />
                            <DataCard title={"Hospitals"} icon={<RiHospitalLine />} value={"5"} />
                        </div>
                    </div>
                </div>

            </Section>
            <Section bg={"gray"}>
                <div className={"home__featured"}>
                    <h2>Featured Programs</h2>
                    <p>
                        Because of your support, we deliver pastoral, education, health and housing assistance
                        to unsupported communities and families in the Diocese of Kikwit and beyond. We have put
                        boys and girls to school, supported clinics and hospitals, provided transportation
                        for ministers (trucks, motorcycles), and initiated entrepreneurial partnerships.
                    </p>
                </div>
                <div style={{height:"2rem"}} />
                <div className={"donation__section"}>
                    <DonationCard title={"Improving living standards"} img={project1} />
                    <DonationCard title={"Boys and girls Education"} img={project2} />
                    <DonationCard title={"Helping families"} img={project3} />
                </div>

            </Section>
            <section>
                <div style={{backgroundColor:"grey"}}>
                    <div className={"home__current-campaign"}>
                        <div className={"campaign__image"} >
                            <img alt={""} src={climbing}
                                 style={{
                                     width:"100%",
                                     height:"100%",
                                     objectFit:"cover"
                                 }}
                            />

                        </div>
                        <div className={"campaign__content-container"} >
                            <div className={"home__event-current"}>
                                <small>Current Activity</small>
                            </div>

                            <h1>
                                Kikwit Kilimanjaro Charity Climb
                            </h1>
                            <Button  title={"SEE MORE DETAILS"} onClick={()=>{ navigate("kikwit-kilimanjaro-charity-climb")}}/>

                            <div style={{height:"2rem"}} />

                            <p>Join Us for an Adventure of a Lifetime!</p>
                            <div style={{height:"1rem"}} />
                            <p>
                                Help Kikwit has partnered with 1,000 Shades of Green Tour &amp; Safari Co. as they have
                                graciously organized a 15-day trip for 40 adventurous individuals to climb Mt.
                                Kilimanjaro at no-profit. Their generosity has allowed us to turn this excursion into a
                                fundraising opportunity.
                            </p>
                            <div style={{height:"1rem"}} />
                            <p>We will provide 40 individuals with the opportunity of a lifetime to summit Africa’s
                                tallest mountain – Mt. Kilimanjaro. In return, we’re asking each person to raise $10K
                                for the experience. From that amount, we will be able to put $6,500 from each
                                climber directly toward construction cost, while the remaining $3,500 will cover all
                                in-country expenses excluding airfare. With the $400,000 raised from individual
                                climbers, along with corporate sponsorship, we will be able to break ground for the
                                <strong> Bilingual School of Leadership of KIKWIT</strong> by July 2024. <br/> The minimum each person is
                                supposed to raise is $5K towards the school.
                            </p>
                            <div style={{height:"2rem"}} />
                            <p><strong>Important Dates:</strong></p>
                            <div style={{height:"0.8rem"}} />
                            <p>January 14 th – Jan 29 th , 2024 – Days of Travel for the entire adventure</p>
                            <div style={{height:"0.8rem"}} />
                            <p>November 30th, 2023 – Each climber MUST raise and submit all funds by this date</p>
                            <div style={{height:"0.8rem"}} />
                            <p>July 30 th , 2023 – Each climber MUST raise at least $5,000 by this date to guarantee his
                                or her spot on the Elevate Education climbing team.
                            </p>

                            <div style={{height:"2rem"}} />
                            <p><strong>Interested in living out this adventure?</strong></p>

                            <div style={{height:"0.8rem"}} />
                            <p>
                                Contact the 1000 Shades of Green’s Director Charles Morgan Kisitu at
                                <a href={"mailto:cmksitu@gogreensafari.com"} style={{color:"#40234a"}}> cmksitu@gogreensafari.com </a>
                                or call <a href={"tel:+256788761007"} style={{color:"#40234a"}}> +256788761007</a> for more information.
                            </p>
                            <div style={{height:"1rem"}} />

                            <p>In USA, call Coordinator,
                                Fr <strong>Jean Pierre Bongila</strong>
                            </p>
                            <div style={{display:"flex", alignItems:"center", gap:"0.5rem"}}>
                               <AiOutlineMail /> <a href={"mailto:jpbongila@helpkikwit.com"} style={{color:"#40234a"}}>jpbongila@helpkikwit.com </a>

                            </div>
                            <div style={{display:"flex", alignItems:"center", gap:"1.2rem"}}>
                                <HiOutlinePhone /> <a href={"tel:+16513073692"} style={{color:"#40234a"}}>+1 6513073692</a>
                            </div>

                            <div style={{height:"2rem"}} />


                            {/*<div className={"first__section-buttons"}>*/}
                            {/*    <Button  title={"MORE ABOUT US"} icon={<BsChevronRight />} onClick={()=>{}}/>*/}
                            {/*    <Button  title={"DONATE"} icon={<BsChevronRight />} onClick={()=>{}}/>*/}
                            {/*</div>*/}

                        </div>

                    </div>
                </div>


            </section>
            <Section>
                <div className={"home__second-section"} >
                    <div className={"second__section-left layout__padding"}>
                        <h1>
                            Donate to help children, communities and families in the Kikwit region and beyond to live better lives now and in the future.
                        </h1>
                        <div style={{height:"2rem"}} />
                        <p>
                            In a world full of challenges, Help Diocese of Kikwit sees the possibility for transformative change.
                            We are expanding our work to various communities, empowering people to survive crisis,
                            allowing ministers to reach out to people, assisting families to build decent houses in order
                            to live better lives and transform their communities for good.
                        </p>
                        <div style={{height:"2rem"}} />


                    </div>
                    <div className={"second__section-right layout__padding"} >
                        <img alt={""} src={second} style={{width:"100%"}} />
                    </div>
                </div>

            </Section>
            <section className={"home__last-section"}>

                <div >
                    <img alt={""} src={last} style={{width:"100%", height:"100%", objectFit:"cover"}} />
                </div>
                <div className={"home__last-content"}>
                    <h2>Subscribe</h2>
                    <p>For <span className={"span"}>good causes</span> that <span className={"span"}>change lives</span></p>

                    <div style={{marginTop:"1rem"}}>
                        <form onSubmit={(e)=>{
                            e.preventDefault()
                            alert(`Your name is : ${fullName} your email is : ${email}.`)
                            setFullName("")
                            setEmail("")
                        }}
                        >
                            <TextInput type={"text"} value={fullName} placeholder={"Full Name"} style={{backgroundColor:"white"}} onChangeHandler={(e:any)=>{setFullName(e.target.value)}} />
                            <TextInput type={"email"} value={email} placeholder={"Email"} style={{backgroundColor:"white"}} onChangeHandler={(e:any)=>{setEmail(e.target.value)}} />
                            {/*<TextInput value={""} placeholder={"Message"} onChangeHandler={()=>{}} isTextArea={true} />*/}
                            <Button title={"Submit"} type={"submit"} onClick={()=>{}} style={{width:"20%", display:"flex", borderRadius:"3%", justifyContent:"center"}}/>
                        </form>

                    </div>

                </div>

            </section>
        </div>
    )

}
