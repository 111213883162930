import React, {FunctionComponent} from "react";
import cover from "../../assets/stats.jpg"
import cards from "../../assets/cards.png"
import "./donatestyle.css"
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {Section} from "../../components/section/Section";
import {DonationCaseH} from "../../components/cards/donationCaseHorizontal/DonationCase";
import {AiOutlineCheck} from "react-icons/ai";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {PayPalButtonWrapper} from "../../components/paypalButtonWrapper/PaypaButtonWrapper";

export const Donate: FunctionComponent =()=>{

    const initialOptions = {
        "client-id": "AWRSn7MVCmzR2VbLdjV6lCD8f_P6cY95ZcRIXuOhKED8RJiRviwzVwFsRvQSO7E6WaRZPhQOcRflbCMV",
        currency: "USD",
        intent: "capture"
    };

    return(
        <div>
            <div className={"about__hero"}>
                <div className={"hero__overlay"}>
                    <img alt={""} src={cover}
                         style={{height:"100%",width:"100%", objectFit:"cover", }}
                    />
                    <div className={"hero__overlay-content"}>
                        <h3 className={"hero__title"}>Donate</h3>
                    </div>
                </div>
            </div>
            <div>
                <Breadcrumb pageTitle={"Donate"} />
            </div>
            <Section>
                <div className={"ways__container"}>
                    <div className={"ways__first-section"}>
                        <div className={"ways__paragraph"}>

                            <h2>Help the Kikwit Community</h2>
                            <p></p>
                            <p>
                                Help Kikwit in Zaire (D.R. Congo) was created in 1997 as a California nonprofit
                                organization, and is currently based in St. Paul, MN. In the D.R. Congo, Help Kikwit
                                supports communities and organizations, particularly the Diocese of Kikwit, by promoting
                                the spiritual, social and economic development of the poorest families and communities.
                            </p>
                            <ul>
                                <li><AiOutlineCheck className={"check__icon"} /> Education of Unsupported Children</li>
                                <li><AiOutlineCheck className={"check__icon"}  />  Improving Transportation</li>
                                <li><AiOutlineCheck className={"check__icon"}  /> Medical Equipment</li>
                                <li><AiOutlineCheck className={"check__icon"}  /> Education of Unsupported Children</li>
                                <li><AiOutlineCheck className={"check__icon"}  />  Affordable Housing</li>
                                <li><AiOutlineCheck className={"check__icon"}  /> Global clinics and Entrepreneurship</li>
                            </ul>
                        </div>
                        <div className={"ways__paragraph"}>

                        </div>

                    </div>
                    <div className={"ways__second-section"}>
                        <div className={"ways__paragraph"}>
                            <h1 style={{textAlign: "center", color: "#20b2aa", fontSize: "40px"}}>DONATE NOW</h1>
                            <PayPalScriptProvider options={initialOptions}>
                                <PayPalButtonWrapper />
                            </PayPalScriptProvider>

                        </div>
                    </div>
                </div>
            </Section>


        </div>
    )
}
