import React, {useState} from 'react';
import './App.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {Container} from "./components/container/Container";
import { Routes, Route, } from "react-router-dom";
import {Home, About, OurWork} from "./pages";
import {Contact} from "./pages/contact/Contact";
import {WaysToHelp} from "./pages/waysToHelp/WaysToHelp";
import {Donate} from "./pages/donate/Donate";
import {MainCampaignPage} from "./pages/mainCampaign/MainCampaignPage";
import {PaymentResultPage} from "./pages/paymentResult/PaymentResultPage";
import {GlobalContext} from "./context/DonationContext";
import {PageNotFound} from "./pages/notFound/PageNotFound";



function App() {
    const [status, setStatus] = useState<string>("")

  return (
      <GlobalContext.Provider value={{status, setStatus}}>
          <div className="App">
              <Container>
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="about" element={<About />} />
                      <Route path="our-work" element={<OurWork />} />
                      <Route path="donate" element={<Donate />} />
                      <Route path="ways-to-help" element={<WaysToHelp />} />
                      <Route path="kikwit-kilimanjaro-charity-climb" element={<MainCampaignPage />} />
                      <Route path="kikwit-kilimanjaro-charity-climb/donation-result" element={<PaymentResultPage />} />
                      <Route path="contact" element={<Contact />} />
                      <Route path="*" element={<PageNotFound />} />
                  </Routes>
              </Container>
          </div>
      </GlobalContext.Provider>

  );
}

export default App;
