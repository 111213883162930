import React, {FunctionComponent, useCallback, useState} from 'react'
import {useGlobalContext} from "../../context/DonationContext";
import {useNavigate} from "react-router-dom";
import {PayPalButtons, usePayPalScriptReducer,} from "@paypal/react-paypal-js";
import TextInput from "../textInput/TextInput";
import "./payst.css"


export const PayPalButtonWrapper: FunctionComponent =()=>{
    const [{  isPending }, dispatch] = usePayPalScriptReducer();

    const [amount, setAmount] = useState("");
    const {status, setStatus} = useGlobalContext()
    const navigate=useNavigate()

    const PayPalButtonsComponent = useCallback(( ) => {
        return (
            <PayPalButtons
                disabled={!amount}
                createOrder={(data, actions)=>{
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: amount,
                                },
                            },
                        ],
                    });
                }
                }

                onCancel={(data, actions)=>{
                    setStatus("CANCELED");
                    navigate("donation-result")
                }}

                onApprove={(data, actions) => {
                    return actions?.order?.capture()?.then((details) => {
                        const status = details.status
                        setStatus(status)
                        const payload = {
                            campaign_name:"Climb charity",
                            name:`${details.payer.name?.given_name} ${details.payer.name?.surname}`,
                            email:details.payer.email_address,
                            amount:details.purchase_units[0].amount.value,
                            currency:details.purchase_units[0].amount.currency_code,
                            payment_id:details.purchase_units[0].payments?.captures ? `${details.purchase_units[0].payments?.captures[0].id}`:""
                        }
                        console.log(details)

                        if(status === "COMPLETED"){
                            console.log("Payload to save ===>", payload)
                            navigate("donation-result"  )
                        }

                    }) || Promise.resolve()
                }}
                style={{tagline:false}}

            />)

    },[amount])

    return(
        <div className={"payCont"} >
            <TextInput
                style={{backgroundColor:"white"}}
                type={"number"}
                value={amount}
                placeholder={"Donation amount"}
                onChangeHandler={(e:any)=>setAmount(e.target.value) }
            />
                    <>
                        {  isPending && <p>Wait a bit...</p> }
                        <PayPalButtonsComponent/>
                    </>
            </div>
    )
}
