import React, {FunctionComponent} from 'react';
import "./paymentresult.css"
import {useGlobalContext} from "../../context/DonationContext";

import success from "../../assets/hk-succ.png"
import cancel from "../../assets/hk-canc.png"


export const PaymentResultPage: FunctionComponent =()=>{
    const {status, setStatus} = useGlobalContext()
    return(
        <div className={"result__container"} style={{backgroundColor:status === "COMPLETED"? "#2c804e":  status ==="CANCELED"? "#a9a9a9":"red"}}>
            <div>
                <img src={status === "COMPLETED"? success: cancel} alt={"success"} style={{width:"25%"}} />
                <h4>Donation's result</h4>
                <p>
                    {
                        status === "COMPLETED"?
                            "Your donation has been completed successfully": status ==="CANCELED"? "Your donation was canceled":
                            "Your donation has failed"
                    }
                </p>

            </div>

        </div>
    )
}
