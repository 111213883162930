import React , {FunctionComponent} from "react"
import "./statstyle.css"

type props ={
    indicator:string
    value:number
}

export const StatsCard : FunctionComponent<props> = ({indicator, value,})=>{
    return(
        <div className={"indicators__container"}>
            <div className={"indicator-item"}>

                    <div className={"indicators__circle"}>
                        <h2>{value}</h2>
                    </div>


                <div style={{height:"1rem"}} />
                <div className={"indicators__content"}>
                    <p className={"indicator__text"}>{indicator}</p>
                </div>
            </div>

        </div>
    )
}
