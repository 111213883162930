import React, {FunctionComponent, } from 'react';
import { Body } from '../body/Body';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import "./container.css"
import ForeHeader from "../foreHeader/ForeHeader";
type  props={
    children:any
}

export const Container : FunctionComponent<props> = ({children})=>{
    return(
        <div className='container__wrapper'>
            <ForeHeader />
            <Header />
            <Body>
                {children}
            </Body>
            <Footer />
            </div>
    )
}


